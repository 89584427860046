



















import { Component, Vue } from "vue-property-decorator";
import TheProgressBar from "@/components/TheProgressBar.vue";

@Component({
  components: {
    TheProgressBar,
  },
})
export default class ViewCompleted extends Vue {}
